import React from 'react';
import { Formik } from 'formik';
import { Router } from '@reach/router'
import { object, string, array } from 'yup'
import useFetch from 'use-http'
import { navigate } from 'gatsby'

import Data from 'contexts/data'
import Input from 'components/Input'
import AreaDropdown from 'components/AreaDropdown'

import Question from './Question'
import Survey from './Survey'
import messages from '../../utils/messages'
import idSchema from '../../utils/idSchema'

const basicData = [
  {
    name: 'orgId',
    label: '調解委員會',
    Comp: AreaDropdown.FastField,
  },
  {
    name: 'fillName',
    label: '筆錄當事人',
    Comp: Input.Field,
  },
  {
    name: 'national_number',
    label: '身份證字號',
    Comp: Input.Field,
    schema: idSchema,
  },
]

const basicValues = basicData.reduce((all, { name }) => {
  all[name] = '';
  return all;
}, {});

const SurveyRouter = ({ id }) => {
  const { post } = useFetch(`/survey/${id}`)

  return (
    <Data path={`/survey/${id}`}>
      {data => {
        if (!data) return null
        const validationSchema = object().shape({
          ...basicData.reduce((all, { name, schema }) => {
            const s = (schema || string()).required(messages.required)
            all[name] = s
            return all
          }, {}),
          ...data.questions.reduce((all, q) => {
            let s
            if (q.sType === 2) {
              s = array().of(string())
              if (q.maxS) s = s.max(q.maxS, messages.max)
            } else {
              s = string()
            }
            if (q.mustFill) s = s.required(messages.required)
            all[`a-${q.id}`] = s;
            return all;
          }, {})
        })
        return (
          <Formik
            initialValues={{
              ...basicValues,
              ...data.questions.reduce((all, q) => {
                all[`a-${q.id}`] = undefined
                return all
              }, {}),
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              post(validationSchema.cast(values))
                .then(() => {
                  navigate('/survey/thanks')
                })
                .catch((err) => {
                  console.error(err);
                  setSubmitting(false)
                })
            }}
          >
            <Router>
              <Survey path="/" id={id} questions={basicData} />
              <Question path="question" id={id} questions={data.questions} />
            </Router>
          </Formik>
        )
      }}
    </Data>
  )
}

export default SurveyRouter;
