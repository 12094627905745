import React from 'react';
import { Router } from '@reach/router'

import ContentLayout from 'components/ContentLayout'

import Survey from 'containers/SurveyPage/SurveyRouter'

export default () => (
  <ContentLayout title="問卷調查">
    <Router>
      <Survey path="survey/do/:id/*" />
    </Router>
  </ContentLayout>
)
