import React from 'react';
import { Form, useFormikContext } from 'formik';

import Button from 'components/Button';
import Board from 'components/Board';
import Box from 'components/Box'
import Flex from 'components/Flex'
// import Text from 'components/Text'
import Link from 'components/Link'
import Image from 'components/Image'
import { responsive } from 'components/ThemeProvider/theme';

import icon from './icon.png'
import text from './text.png'

const SubmitButton = ({ to }) => {
  const { errors, values } = useFormikContext();
  return (
    <Button
      as={(p) => <Link color="white" {...p} />}
      fontSize="1.5em"
      type="button"
      to={to}
      disabled={!values.national_number || errors.orgId || errors.national_number || errors.fillName}
    >開始填寫</Button>
  )
}

const Survey = ({ id, questions, ...props }) => {
  return (
    <Box {...props}>
      <Form>
        <Board>
          <Flex flexDirection="column" alignItems="center">
            <Box width={responsive('7.25em', '8.875em')} mt={responsive('2em', 0)}><Image src={icon} alt="問卷Icon" /></Box>
            <Box width={responsive('12em', '16.875em')} mt="1.25em"><Image src={text} alt="歡迎填寫問卷" /></Box>
          </Flex>
          <Flex flexWrap="wrap" alignItems="center">
            {questions.map(({ name, Comp, label }, i) => (
              <Box
                mt="2.25em"
                width={responsive(1, 1 / 2)}
                px={responsive('0.5em', '1.5em')}
                position="relative"
                key={i}
              >
                <Comp
                  name={name}
                  label={label}
                />
              </Box>
            ))}
          </Flex>
          <Box textAlign="center" mt="3em">
            <SubmitButton to={`/survey/do/${id}/question`} />
          </Box>
        </Board>
      </Form>
    </Box>
  )
};

export default Survey;
