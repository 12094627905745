import React from 'react'
import styled from 'styled-components';
import { Redirect } from '@reach/router'

import { useFormikContext, ErrorMessage, Form } from 'formik';

import Button from 'components/Button';
import Board from 'components/Board';
import Box from 'components/Box'
import Input from 'components/Input'
import Text from 'components/Text'
import { responsive } from 'components/ThemeProvider/theme';

// import useResponsive from 'contexts/mediaQuery/useResponsive'

const types = ['', 'radio', 'checkbox', 'text']

const IncrementalSetup = styled(Box)`
  counter-reset: questions;
`

const IncrementalText = styled(Text)`
  &::before {
    counter-increment: questions;
    content: counter(questions) ". ";
  }
  ${({ required, theme }) => required && `
    &::after {
      content: "*";
      color: ${theme.colors.danger};
      vertical-align: super;
    }
  `}
`

const SubmitButton = ({ id }) => {
  const { isSubmitting, values } = useFormikContext();
  if (!values.national_number) return <Redirect to={`/survey/${id}`} />
  return <Button fontSize="1.5em" type="submit" disabled={isSubmitting}>送出問卷</Button>
}

const Question = ({ questions, id: sId, ...props }) => {
  // const { isMobile } = useResponsive()
  return (
    <Box {...props}>
      <Form>
        <Board pt="2em">
          <IncrementalSetup>
            {questions.map(({ id, options, question, mustFill, sType, maxS }, i) => {
              const name= `a-${id}`;
              return (
                <Box
                  mt="1.5em"
                  width={1}
                  px={responsive('0.5em', '1.5em')}
                  key={name}
                >
                  <IncrementalText
                    fontSize={responsive('1em', '1.375em')}
                    mb="1em"
                    required={mustFill}
                  >
                    {question}
                  </IncrementalText>
                  <Input.Field
                    options={options}
                    name={name}
                    type={types[sType]}
                    max={maxS}
                  />
                  <ErrorMessage name={name}>
                    {msg => msg && (
                      <Text color="danger">{msg}</Text>
                    )}
                  </ErrorMessage>
                </Box>
              )
            })}
          </IncrementalSetup>
          <Box textAlign="center" mt="2.25em">
            <SubmitButton id={sId} />
          </Box>
        </Board>
      </Form>
    </Box>
  )
}

export default Question
